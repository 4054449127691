body, html {
    height: 100%;
  }

.bdy{
    background-image: url(./media/library.jpg);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}


.sign-in-box {
  width: 400px;
  height: 400px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 50px 30px;
  border-radius: 10px;

}